@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,400;0,500;1,300;1,400&display=swap");

/*  Global style */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    font-family: "Montserrat", sans-serif;
    text-decoration: none;
}

li {
    list-style: none;
}

p {
    font-family: "Montserrat", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Montserrat", sans-serif;
}

body {
    background-color: #470e00;
}

/*  global style end */

/* navbar-START */

#header-background {
    max-width: 100%;
    /* background-image: linear-gradient(
        180deg,
        rgba(20, 20, 20, 0.2) 48.44%,
        #070000 80%
    ),url(./bgsword.jpeg); */
    /* opacity: 0.5; */
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    left: 20%;
    z-index: 10;
    top: -0.5%
}

.navbar {
    display: flex;
    max-width: 1232px;
    padding: 50px 0 15px;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin: 0 auto;
}

.navbar ul li {
    list-style: none;
    display: inline-block;
    margin: 0px 15px;
}

.navbar ul li a {
    font-size: 22px;
    line-height: 25px;
    color: #ffffff;
    font-family: "Montserrat", sans-serif;
}

.ctn {
    margin: 0px 0 0 15px;
    border-color: rgba(255, 255, 255, 0.8);
    font-family: Montserrat, sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    border-width: 1px;
    border-style: solid;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    text-transform: none;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    background: linear-gradient(rgba(107,0,0,1), rgba(150,1,1,1));
    opacity: 1;
    color: rgba(255, 255, 255, 0.8);
    padding: 11px 23px;
    line-height: 16px;
    font-size: 16px;
    z-index: 1;
}

/* Header start */
.hero-section-main {
    display: flex;
    justify-content: space-between;
    align-content: center;
    max-width: 1232px;
    /* margin: auto; */
    /* margin-left: 6.5%; */
    padding: 30px 0;
    margin-left: auto;
    margin-right: auto;
}

.hero-background {
    background-image: 
        url(./bgsword.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0;
    padding-top: 5%;
}

.hero-section-text {
    z-index: 1;
}

.hero-section-text h1 {
    font-size: 70px;
    font-stretch: ultra-expanded;
    line-height: 65px;
    margin-bottom: 30px;
    font-weight: bold;
    background: white;
    /* background: linear-gradient(180deg, #f6f6f6 0%, #4E0000 100%); */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    letter-spacing: 19px;
    font-family: 'Sedan SC', sans-serif;
}

.hero-section-text h3 {
    font-size: 42px;
    line-height: 65px;
    margin-bottom: 30px;
    /* padding: 0 115px; */
    font-weight: bold;
    background: linear-gradient(180deg, #DE0000 0%, #4E0000 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    font-family: 'Sedan SC', sans-serif;
    word-spacing: 15px;
}

.italicise {
    font-style: italic;
}

.hero-section-text {
    margin-top: 35px;
    padding-right: 90px;
    margin-left: -5.5%;
}

.hero-section-p {
    width: 750px;
}

/* .hero-section-text div:nth-child(0) {
    width: 870px;
}

.hero-section-text div:nth-child(1) {
    width: 750px;
} */

.hero-section-text p {
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    /* padding-bottom: 35px; */
    color: #ffffff;
    padding: 0 40px 0 0;
}

.hero-section-text li {
    font-size: 20px;
    list-style-position: outside;
    list-style: disc;
    font-weight: 400;
    line-height: 36px;
    /* padding-bottom: 35px; */
    color: #ffffff;
    padding: 0 40px 0 0;
		margin-left: 3%;

}

/* Add a right margin to the loading-spinner icon */
.fa {
    margin-left: -12px;
    margin-right: 8px;
    font-weight: bolder;
    font-size: 25px;
}

.services-text-bullets li {
    font-size: 20px;
    list-style-position: outside;
    list-style: disc;
    font-weight: 400;
    line-height: 36px;
    /* padding-bottom: 35px; */
    color: #ffffff;
    padding: 0 40px 0 0;
		margin-left: 4%;
}

.hero-section-text p a { 
    line-height: unset;
    font-size: unset;
    padding: unset;
    background-image: unset;
    border-color: unset;
    border-width: unset;
    border-radius:unset;
    /* color: unset; */
    letter-spacing: unset;
    color: #DE0000;
    font-weight: 600;
}

.hero-section-text a {
    line-height: 17px;
    font-size: 16px;
    padding: 13px 70px;
    background-image: linear-gradient(180deg, #508af9 0%, #4f4bcf 100%);
    border-color: rgba(255, 255, 255, 0.8);
    border-width: 0px;
    border-radius: 6px;
    color: #fff;
    letter-spacing: 1px;
}

.hero-text-img {
    border-radius: 7.73376px;
    /* width: 509px; */
    width: 60%;
    padding: 40px;
    text-align: center;
    margin-left: -5%;
    margin-top: 3%;
}

.kevin-smith-img-bg {
    /* border-radius:500px;
    filter: blur(150px);
	background-color:rgba(0, 0, 0, 1); */
    width: 1400px;
    height: 1200px;

    position: absolute;
    top: -34%;
    
    left: -135%;
}



.hero-img-time {
    text-align: center;
    /* padding: 25px 0 10px; */
    display: inline-block;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2em;
    line-height: 29px;
    text-align: left;
    letter-spacing: 0.05em;
    color: #ffffff;
    margin-top: 20%;
}

.mintPrice {
    /* display: inline-block; */
    /* align-items: center;
    justify-content: space-between; */
}

.priceTitle div {
    font-weight: 400;
    font-size: 20px;
    /* line-height: 29px; */
    text-align: center;
    /*letter-spacing: 0.05em;*/
    color: #ffffff;
}

.mint {
    text-align: center;
    /* margin: 33px 40px 0; */
}

.mint a {
    font-family: Montserrat, sans-serif;
    /* font-size: 22px; */
    font-weight: bold;
    box-sizing: border-box;
    background: linear-gradient(180deg, #DE0000 0%, #6B0000 100%);
    border: 2px solid #FFFFFF;
    border-radius: 7.73376px;
    cursor: pointer;
}

/* services-section */

#services-background {
    /* background-image: linear-gradient(
            180deg,
            #1b0505 0%,
            rgba(255, 0, 0, 0.39) 48.44%,
            #1d040e 100%
        ),
        url(./bglight.jpeg); */
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0;
    margin-top: -10%;
    margin-bottom: 5%;
}

.services-section-main {
    display: flex;
    justify-content: space-between;
    align-content: center;
    /* padding-top: 83px; */
    margin: auto;
    max-width: 1230px;
}

.services-img img {
    max-width: 600px;
    width: 500px;
    height: 500px;
    border: #dde3e4;
    border-width: 2px;
    border-style: solid;
    border-radius: 10px;
    background: linear-gradient(180deg, #0a0101 0%, #8e0a0a 100%);
}

.services-img {
    margin-top: 1.5rem;
}

.services-text {
    padding: 0 0 0 128px;
    margin-top: 1%;
    z-index: 1;
}

.services-text p {
    font-size: 20px;
    line-height: 36px;
    font-family: "Montserrat", sans-serif;
    color: #ffffff;
}

.services-text a {
    line-height: 17px;
    font-size: 16px;
    padding: 13px 70px;
    background-image: linear-gradient(180deg, #DE0000 0%, #6B0000 100%);
    /* background: linear-gradient(180deg,rgba(222, 0, 0, 1) 0%,rgba(107, 0, 0, 1) 100%); */
    border: 2px solid rgba(255, 255, 255, 1);
    border-radius: 7.73px;
    color: rgba(255, 255, 255, 1);
    margin-top: 30px;
    font-size: 22px;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  margin-left: 5%;
}

/* ROADMAP-start */

#roadmap-background {
    background: linear-gradient(
            180deg,
            #000000 0%,
            rgb(0, 0, 0) 48.44%,
            #000000 100%
        );
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0;
    margin: auto;
    padding: 60px 0px;
    z-index: 1;
}

.background-zig-zag {
    background-image: url(./roadmap-side.png);
    background-repeat: no-repeat;
    position: relative;
    height: 64em;
    margin: auto;
    max-width: 375px;
    background-position: center;
    background-size: contain;
}

.main-content {
    width: 320px;
    color: #fff;
    position: relative;
}

.title {
    font-size: 30px;
    line-height: 32px;
    position: absolute;
    top: 98px;
    left: -102px;
    font-family: "Montserrat", sans-serif;
    color: #ffffff;
    font-weight: bolder;
}

/* .title::after {
    content: "";
    width: 14px;
    height: 14px;
    color: #fff;
    position: absolute;
    right: -21px;
    top: 10px;
    background: #FF0000;
    border-radius: 50%;
    z-index: 1111;
} */

.content-text {
    margin: 0px 0 0 0;
    position: absolute;
    top: 145px;
    /* left: 82px; */
    margin-right: 38%;
    margin-left: -88%;
    /* text-align: left; */
}

.content-text h3 {
    font-family: "Montserrat", sans-serif;
    /* font-size: 22px; */
    line-height: 26px;
    color: #FFFFFF;
    padding-bottom: 9px;
    font-weight: 700;
    font-size: 14px;
}

.content-text p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: justify; /* For Edge */
    -moz-text-align-last: left; /* For Firefox prior 58.0 */
    text-align-last: left;
}

.main-content-two {
    width: 567px;
    color: #fff;
    position: relative;
}

.title-two {
    position: absolute;
    top: 392px;
    left: -206px;
    font-family: "Montserrat", sans-serif;
    font-size: 30px;
    line-height: 32px;
    color: #ffffff;
    font-weight: bolder;
}

/* .title-two::after {
    content: "";
    width: 14px;
    height: 14px;
    background: #fff;
    color: #fff;
    position: absolute;
    right: -31px;
    top: 10px;
    background: #FF0000;
    border-radius: 50%;
    z-index: 1111;
} */

.content-text-two {
    margin: 0px 0 0 0;
    position: absolute;
    top: 350px;
    left: 83px;
    text-align: left;
}

.content-text-two h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 22px;
    line-height: 26px;
    /* color: #31D7FC; */
    padding-bottom: 9px;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 14px;
}

.content-text-two p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    padding-right: 21px;
    /* margin-top: 1%; */
}

.main-content-three {
    width: 567px;
    color: #fff;
    position: relative;
}

.title-three {
    margin: 0 0 40px;
    position: absolute;
    top: 593px;
    font-family: "Montserrat", sans-serif;
    font-size: 30px;
    line-height: 32px;
    color: #ffffff;
    font-weight: bolder;
    bottom: 0;
    right: -10px;
}



.content-text-three {
    margin: 0px 0 0 0;
    position: absolute;
    top: 575px;
    left: -35px;
    text-align: left;
    width: 450px;
}

.content-text-three h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 22px;
    line-height: 26px;
    padding-bottom: 9px;
    /* color: #9969ff; */
    padding-right: 24px;
    padding-top: 7px;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 14px;
}

.content-text-three p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    padding-right: 21px;
}

.main-content-four {
    width: 567px;
    color: #fff;
    position: relative;
}

.title-four {
    font-size: 30px;
    line-height: 35px;
    margin: 0 0 40px;
    position: absolute;
    top: 790px;
    left: -213px;
    font-family: "Montserrat", sans-serif;
    font-size: 30px;
    line-height: 32px;
    color: #ffffff;
    font-weight: bolder;
}

.content-text-four {
    margin: 0px 0 0 0;
    position: absolute;
    top: 755px;
    left: 80px;
    text-align: left;
    max-width: 463px;
}

.content-text-four h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 22px;
    line-height: 26px;
    padding-bottom: 9px;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 14px;
}

.content-text-four p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    padding-right: 21px;
    padding-bottom: 12px;
}

.main-content-five {
    width: 567px;
    color: #fff;
    position: relative;
}

.title-five {
    font-size: 30px;
    line-height: 35px;
    position: absolute;
    top: 930px;
    font-family: "Montserrat", sans-serif;
    color: #ffffff;
    font-weight: bolder;
    bottom: 0;
    right: -23px;
}

.title-five::before {
    content: "";
    width: 14px;
    height: 14px;
    position: absolute;
    top: 10px;
    background: #ff4f4f;
    border-radius: 50%;
    z-index: 1111;
    left: -30px;
    bottom: 0;
}

.content-text-five {
    margin: 0px 0 0 0;
    position: absolute;
    top: 875px;
    left: -112px;
    text-align: right;
    right: 239px;
}

.content-text-five h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 22px;
    line-height: 26px;
    padding-bottom: 9px;
    color: rgba(51, 243, 70, 1);
    padding-right: 24px;
    padding-top: 7px;
}

.content-text-five p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    padding-right: 21px;
    margin-top: -7%;
}

.main-content-six {
    width: 567px;
    color: #fff;
    position: relative;
}

.title-six {
    font-size: 30px;
    line-height: 35px;
    position: absolute;
    top: 1018px;
    left: -233px;
    font-family: "Montserrat", sans-serif;
    color: #ffffff;
    font-weight: bolder;
}

.title-six::after {
    content: "";
    width: 14px;
    height: 14px;
    background: #fff;
    color: #fff;
    position: absolute;
    right: -21px;
    top: 10px;
    background: rgba(51, 128, 243, 1);
    border-radius: 50%;
    z-index: 1111;
}

.content-text-six {
    margin: 0px 0 0 0;
    /* padding: 23px 95px 0 0px; */
    position: absolute;
    top: 965px;
    left: 75px;
    text-align: left;
    right: -12px;
}

.content-text-six h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 22px;
    line-height: 26px;
    padding-bottom: 9px;
    color: rgba(51, 128, 243, 1);
}

.content-text-six p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    padding-right: 60px;
}

/* F.A.Q */
#accordian-background {
    background-image: linear-gradient(
            180deg,
            #000000 20%,
            rgba(83, 74, 74, 0.263) 40.44%,
            #000000 100%
        ),
        url(./bgtree.jpeg);
    /* width: 100%; */
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: -3%;
    margin-top: -3%;
    padding-bottom: 10%;
    /*background-position: 0;*/
    background-position-y: -220px;
    z-index: 10;
}

.faq-set {
    text-align: center;
    margin: 0 auto;
    max-width: 1230px;
}

.accordion {
    background: transparent;
    color: #ffffff;
    cursor: pointer;
    padding: 25px 18px;
    width: 932px;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
    margin: 31px 0 0px;
    line-height: 22px;
    font-weight: 400;
    background: rgba(0, 0, 0, 0.56);
    border: 1px solid #5e5e5e;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Montserrat, sans-serif;
}

.active,
.accordion:hover {
    /* background: linear-gradient(180deg, #5089f9 100%, #4f4bcf 0%); */
    color: #ffffff;
    border: unset;
    border-radius: 8px 8px 0 0;
    margin-bottom: -2px;
    border: 2px solid #fff;
    border-bottom: unset;
}

.accordion:hover {
    border: 2px solid #fff;
    box-sizing: border-box;
    border-radius: 8px;
}

.active,
.panel {
    color: #ffffff;
}

.panel {
    display: none;
    background: linear-gradient(180deg, #000000 0%, #6B0000 100%);
    overflow: hidden;
    margin: 0;
    width: 932px;
    padding: 18px;
    margin: 0 auto;
    border-radius: 0 0 8px 8px;
    border: 2px solid;
    border-top: unset;
}

.panel p {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    padding: 12px 0;
}

.panel p a {
    text-decoration: underline;
}

.accordion::after {
    /* background-image: url(./twitter.png); */
    font-size: 30px;
    margin-top: 0px;
    color: #ffff;
    font-weight: bold;
    float: right;
    margin-left: 5px;
    transform: rotate(-360deg);
    font-family: "fontawesome";
    content: url(./Vector.png);
}

button.accordion.active:after {
    content: url(./Vector.png);
    transform: rotate(-180deg);
    font-size: 30px;
}

/* OUR-TEAM */
#team-footer-background {
    background-image: linear-gradient(
            180deg,
            #030001 0%,
            rgba(110, 3, 3, 0.682) 100.44%,
            #090101 100%
        );
    /* background-position: 100% 100%; */
    width: 100%;
    height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: -5%;
}

.OUR-TEAM {
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    background: linear-gradient(180deg, #5092ff 0%, #4f4bcf 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-top: 100px;
    padding-bottom: 24px;
    margin-top: 30px;
}

.grim-title {
    font-size: 40px;
    line-height: 65px;
    margin-bottom: 30px;
    /* background: linear-gradient(180deg, #DE0000 0%, #6B0000 100%); */
    background: linear-gradient(180deg, #DE0000 0%, #6B0000 800%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Montserrat';
    font-weight: 1000;
}

.live {
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: stretch;
    padding: 55px 0px;
    max-width: 1230px;
    margin: auto;
}

.mobilecol {
    display: flex;
    max-width: 50%;
}

.live-img-1 {
    padding: 16px 10px 1px;
    width: 50%;
    border-radius: 8px 0 0 8px;
    background: linear-gradient(180deg, #5092ff 0%, #4f4bcf 100%);
    margin: 0;
    border: 2px solid white;
    border-right: unset;
}

.live-img-1-2 {
    padding: 16px 10px 1px;
    width: 50%;
    border-radius: 0 8px 8px 0;
    background: linear-gradient(180deg, #5092ff 0%, #4f4bcf 100%);
    margin: 0 15px 0 -1px;
    border: 2px solid white;
    border-left: unset;
}

.live-img-1-3 {
    padding: 16px 10px 1px;
    width: 33%;
    border-radius: 0 8px 8px 0;
    background: linear-gradient(180deg, #5092ff 0%, #4f4bcf 100%);
    margin: 0 15px 0 -1px;
    border: 2px solid white;
    border-left: unset;
}

.live-img-3 {
    padding: 16px 10px 1px;
    width: 25%;
    border-radius: 8px;
    background: linear-gradient(180deg, #5092ff 0%, #4f4bcf 100%);
    margin: 0 15px 0 0;
    border: 2px solid white;
}

.live-img-4 {
    padding: 16px 10px 1px;
    width: 25%;
    border-radius: 8px;
    background: linear-gradient(180deg, #5092ff 0%, #4f4bcf 100%);
    margin: 0;
    border: 2px solid white;
}

.live-img-4 img {
    width: 100%;
    border-radius: 15px;
}
/* .live-img-1:hover {
    background: whitesmoke;
} */
.live-img-1 img {
    width: 100%;
    border-radius: 15px;
}

.live-img-1-2 img {
    width: 100%;
    border-radius: 15px;
}

.live-img-1-3 img {
    width: 100%;
    border-radius: 15px;
}

.live-img-3 img {
    width: 100%;
    border-radius: 15px;
}

.live-img-1-content {
    padding: 9px 0;
}

.content-absolute {
    position: absolute;
}

.live-img-1-content h3 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    color: #ffffff;
}

.live-img-1-content p {
    font-size: 17px;
    line-height: 22px;
    padding-top: 5px;
    color: #ffffff;
    font-weight: 400;
    padding-top: 12px;
    padding-right: 16px;
}

.live-img-1-content span {
    float: right;
    font-weight: bold;
}

.live-img-1-content span img {
    width: 42%;
}

.team-role {
    font-size: 16px;
    font-weight: 300 !important;
    font-family: "Montserrat", sans-serif;
}

/* FOOTER */

.footer-main {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    /* padding-right: 24%; */
    /* padding-left: 8%; */
    padding-top: 100px;
    max-width: 1230px;
    margin: auto;
}

.footer-bg {
    background-image: linear-gradient(
            180deg,
            #310303 0%,
            rgba(20, 20, 20, 0.2) 18.44%,
            #070101 93%
        ),
        url(./bgsword.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0;
}

.footer-bg-my-collection {
    /* background-image: linear-gradient(
            180deg,
            #310303 0%,
            rgba(20, 20, 20, 0.2) 18.44%,
            #070101 93%
        ),
        url(./bgsword.jpeg);
    background-repeat: no-repeat;
    background-size: cover; */
    /* background-position: 0; */
}

.footer-input {
    position: relative;
}

.footer-text img {
    padding: 10px 25px 30px 0px;
    margin-top: 10%;
}

.footer-img {
    position: absolute;
    right: 15px;
    bottom: 12px;
}

.footer-text p {
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    color: #ffffff;
}

.footer-text h5 {
    font-size: 20px;
    font-weight: 400;
    line-height: 22px;
    padding: 21px 0;
    color: #ffffff;
}

.footer-input p {
    font-size: 22px;
    font-weight: 600;
    line-height: 27px;
    padding-bottom: 25px;
    color: #ffffff;
}

input.form-control {
    padding: 18px 70px 18px 17px;
    border-radius: 9px;
    width: 362px;
    font-size: 12px;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    font-weight: bold;
}

/* FOOTER-LAST */

.empty-line {
    padding: 23px 0 10px;
    max-width: 1230px;
    margin: auto;
    border-top: 1px solid #343434;
}

.footer-main-last {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    /* padding-right: 18%; */
    /* padding-left: 8%; */
    max-width: 1230px;
    margin: auto;
    padding-bottom: 1%;
}

.footer-text-1 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    color: #808080;
    margin: auto;
  width: 20%;
}
/* ====================================================================== */
/* ====================================================================== */
/* ======================================================================*/

/* MY COLLECTION */

.my-collection {
    text-align: center;
    padding: 100px 15px 30px;
    /* background: linear-gradient(
        180deg,
        #480101 0%,
        rgba(20, 20, 20, 0.39) 48.44%,
        #480101 100%
    ); */
    background: transparent;

}

.my-collection h1 {
    font-style: normal;
    font-size: 92px;
    line-height: 112px;
    text-transform: uppercase;
    background: white;
    /* background: linear-gradient(180deg, #DE0000 0%, #6B0000 100%); */
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-family: 'Sedan SC', sans-serif;
    font-weight: 1000;

    margin-bottom: 15px;
}

.passphrase-box {
    position: relative;
    height: 100px;
    margin-bottom: 4%;
}

.lock-image-wrapper {
    position: absolute;
    top: 64%;
    left: -2%;
    width: 100%;
    height: 100%;
}

.container{
    margin-top: 1.5%;
    width: 30%;
    height: 200px;
    position: relative;
    margin-left: 35%;
    margin-bottom: -7%;
}
.abs-box{
    width: 100%;
    height: 50%;            
    position: absolute;
    top: 0;
    left: 0;
    /* opacity: 0.8; */
}
.stack-top{
    z-index: 9;
    z-index: 4;
    /* position: absolute; */
    margin-top: 6%;
}
.passphrase-lock {
    width: 35px !important;
    height: 50px;
 
}

.passphrase-text {
    top: 25%;
    left: 33%;
    z-index: 1;
    /* margin-top: -3%;
    margin-left: -16.5%; */

    width: 500px;
    height: 130px;
    font-family: 'Radley';
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 150%;
    letter-spacing: 0.08em;
    
    color: #FFFFFF;
    border-radius: 4px;

    border: none;
    position: absolute;
    border-radius: 10px;
    background: linear-gradient(
        180deg,
        #480101 0%,
        rgba(10, 0, 0, 0.39) 48.44%,
        #480101 100%
    );
}

.passphrase-text2 {
    font-family: 'Radley';
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 150%;
    letter-spacing: 0.08em;
    
    color: #FFFFFF;
    border-radius: 4px;

    border: none;
    border-radius: 10px;
    background: linear-gradient(
        180deg,
        #480101 0%,
        rgba(10, 0, 0, 0.39) 48.44%,
        #480101 100%
    );
}

.container .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #D9D9D9;
    color: #000000;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -5px;
    right: 63%;
  }
  .container .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 15%;
    margin-left: -5px;
    border-width: 8px;
    border-style: solid;
    border-color: #D9D9D9 transparent transparent transparent;
  }
  .container:hover .tooltiptext {
    visibility: visible;
  }

.my-collection p {
    font-size: 20px;
    line-height: 36px;
    font-weight: 400;
    color: #ffffff;
}

/* YETI-START */

.my-collection-yeti {
    padding: 3rem 10px 60px;
    border-radius: 8px;
    background: transparent;
    text-align: center;
    margin: 0px auto;
    position: relative;
}

.no-nfts {
    font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 30px;
line-height: 36px;
/* or 120% */

text-align: center;

color: #FFFFFF;
/* margin-bottom: 20%; */
margin-top: 5%;
height: 400px;
}

.nft-note {
    margin-bottom: -15%;
}

.flex-col-hcenter {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .flex-row {
    display: flex;
    flex-direction: row;
  }
  
  .flex-col-hcenter-vcenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .flex-hcenter {
    justify-content: center;
  }
  
  .group-776 {
    padding: 0px 0px 0.36px 0px;
    box-sizing: border-box;
    width: 254%;
    margin-top: -38%;
    margin-right: 117%;
    z-index: 1;
  }
  
  .group-666 {
    padding: 0px 0px 0.36px 0px;
    box-sizing: border-box;
    width: 100%;
  }
  
  .group-965 {
    padding: 21px 38px 23px 45px;
    margin-bottom: 54px;
    box-sizing: border-box;
    background-color: rgba(41, 0, 0, 1);
    box-shadow: 9.31px 0px 26.07px rgba(0, 0, 0, 0.25);
    width: 100%;
  }
  
  .group-079 {
    margin-bottom: 34px;
    box-sizing: border-box;
  }
  
  .lgnd-logo-red-111 {
    width: 141px;
    height: 37px;
    margin-right: 170px;
  }
  
  .txt-438 {
    font-size: 29.8px;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    line-height: 125%;
    color: rgba(178, 186, 199, 1);
    width: 22px;
    height: 39px;
    word-wrap: break-word;
  }
  
  .button {
    padding: 20.08px 0;
    margin-bottom: 41px;
    box-sizing: border-box;
    border-radius: 5.39px;
    border: 1px solid rgba(255, 255, 255, 1);
  }
  
  .wallet-41 {
    width: 22px;
    height: 22px;
    margin-left: 26%;
  }
  
  .txt-469 {
    font-size: 20px;
    font-family: Average Sans, sans-serif;
    font-weight: 400;
    /* line-height: 80%; */
    color: rgba(255, 255, 255, 1);
    /* text-align: center; */
    width: 220px;
    height: 14px;
    word-wrap: break-word;
    margin-left: 5%;
    margin-top: 0.5%;
  }
  
  .line-49 {
    border: 0.93px solid rgba(255, 255, 255, 0.1);
    width: 242.49px;
    height: 0.93px;
    margin-bottom: 27px;
  }
  
  .txt-571 {
    font-size: 20px;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    line-height: 93%;
    /* color: rgba(250, 137, 137, 1); */
    color: rgba(178, 186, 199, 1);
    text-align: center;
    width: 150px;
    height: 20px;
    word-wrap: break-word;
    margin-bottom: 29px;
  }
  
  .line-43 {
    border: 0.93px solid rgba(255, 255, 255, 0.1);
    width: 242.49px;
    height: 0.93px;
    margin-bottom: 26px;
  }
  
  .txt-097 {
    font-size: 20px;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    line-height: 93%;
    color: rgba(178, 186, 199, 1);
    text-align: center;
    width: 161px;
    height: 19px;
    word-wrap: break-word;
    margin-bottom: 29px;
  }
  
  .txt-418 {
    font-size: 20px;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    line-height: 93%;
    color: rgba(178, 186, 199, 1);
    text-align: center;
    width: 174px;
    height: 20px;
    word-wrap: break-word;
    margin-bottom: 30px;
  }
  
  .line-45 {
    border: 0.93px solid rgba(255, 255, 255, 0.1);
    width: 242.49px;
    height: 0.93px;
    margin-bottom: 25px;
  }
  
  .line-46 {
    border: 0.93px solid rgba(255, 255, 255, 0.1);
    width: 242.49px;
    height: 0.93px;
  }
  
  .line-47 {
    border: 0.93px solid rgba(178, 186, 199, 0.1);
    width: 242.49px;
    height: 0.93px;
  }
.live-my-collection {
    display: flex;
    align-items: center;
    flex-direction: column;
    align-content: space-between;
    max-width: 415px;
    justify-content: center;
    margin: auto;
}

.live-my-collection h4 {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    text-align: left;
    color: #ffffff;
    width: 415px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 10px 0 0 15px;
}

.live-my-collection h4 span {
    text-align: right;
    font-weight: 300;
    font-size: 18px;
    padding-left: 19px;
    font-family: "Roboto", sans-serif;
    line-height: 30px;
    color: #ffffff;
}

.live-my-collection p {
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    font-family: "Roboto", sans-serif;
    color: #ffffff;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 410px;
    padding: 10px 0 0 15px;
}

.live-my-collection p span {
    font-size: 20px;
    text-align: right;
    font-weight: 300;
    padding-left: 92px;
    font-family: "Roboto", sans-serif;
    line-height: 30px;
    color: #ffffff;
}

.my-collection-yeti img {
    width: 415px;
    border-radius: 9px;
}

#customers th {
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
    background: linear-gradient(180deg, #1b315a 0%, #1b2d57 20%, #1b2854 75%);
    color: white;
}

tr {
    background: lightcoral;
}

/* tbody {
    border: 3px solid rgba(71, 86, 110, 0.8);
} */
table {
    border-collapse: separate;
    border-spacing: 0 18px;
    margin: 0 auto;
}

th {
    background-color: #4287f5;
    color: white;
}

th,
td {
    width: 150px;
    text-align: center;
    padding: 5px;
}

tr .custumers {
    border: 2px solid white;
}

.tableBg {
    background: linear-gradient(
        180deg,
        #011b48 0%,
        rgba(20, 20, 20, 0.39) 48.44%,
        #011b48 100%
    );
}

.customerbodyTable {
    background: rgba(14, 29, 52, 0.8);
    border: 1px solid #9b9bff;
    box-sizing: border-box;
    border-radius: 10px;
}

.customerTableheadInner {
    background: rgba(14, 29, 52, 0.8);
    border: 1px solid #9b9bff;
    box-sizing: border-box;
    border-radius: 7px;
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    align-content: center;
    padding: 20px 40px;
    /* padding: 20px 0px 20px 30px; */
}

p.headText {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 150%;
    /* identical to box height, or 42px */
    color: #ffffff;
    text-align: center;
}

.customerTablerow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: center;
    padding: 20px 60px 20px 20px;
    margin: 20px 15px;
    border: 2px solid;
    border-image-source: linear-gradient(180deg, #5092ff 0%, #4f4bcf 100%);
    border: 1px solid transparent;
    border-radius: 7px;
    background: linear-gradient(rgba(14, 29, 52), rgba(14, 29, 52)) padding-box,
        linear-gradient(180deg, #5092ff 0%, #4f4bcf 100%) border-box;
}

p.paraText {
    text-align: center;
    position: absolute;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #a7a7a7;
}

.justDiv {
    margin-left: 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: center;
}

.justDivLast {
    padding-left: 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: center;
}

.customerTable {
    max-width: 1232px;
    margin: auto;
}

p.headTextcenter {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 150%;
    color: #ffffff;
    text-align: center;
}

.headTextlast {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 150%;
    color: #ffffff;
    text-align: center;
}

p.paralast {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.05em;
    color: #a7a7a7;
    /* text-align: end; */
}

/* pop up start */
.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: hidden;
    opacity: 0;
    z-index: 1;
}

.overlay:target {
    visibility: visible;
    opacity: 1;
}

.overlay-mint {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: hidden;
    opacity: 0;
}

.mint-visible {
    visibility: visible;
    opacity: 1;
}

.popup-width {
    max-width: 1230px;
    margin: auto;
}

.popup {
    margin: 70px auto;
    padding: 30px;
    max-width: 500px;
    position: relative;
    transition: all 5s ease-in-out;
    background: linear-gradient(180deg, #FF5050 0%, #000000 100%);
    border: 2px solid #ffffff;
    border-radius: 10px;
    float: right;
}

.popup h2 {
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    line-height: 120%;
    margin-bottom: 25px;
    letter-spacing: 0.05em;
    color: #ffffff;
}

.popup .close {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 24px;
    font-weight: bold;
    text-decoration: none;
    color: #fff;
}

.popup .close:hover {
    color: #fff;
}

.popup .content {
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    line-height: 120%;
    letter-spacing: 0.05em;
    color: #ffffff;
    margin-bottom: 50px;
}

.mintbox-content {
    font-size: 18px;
}

a.barkbehance {
    background: #ffffff;
    border-radius: 100px;
    padding: 13px 50px;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.05em;
    color: #0b131f;
    margin-left: 2.5rem;
}

.innercontent {
    display: flex;
    align-items: center;
}

.innercontent img {
    padding: 5px 20px 5px 0;
}

a.bark {
    background: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 100px;
    padding: 13px 20px;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: 0.05em;
    color: #000000;
}

a.light {
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 100px;
    padding: 13px 20px;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: 0.05em;
    color: #ffffff;
}

@media screen and (max-width: 700px) {
    .box {
        width: 70%;
    }

    .popup {
        width: 70%;
    }
}

header a {
    text-decoration: none;
}

header {
    height: 100px;
    display: flex;
    justify-content: space-between;
    max-width: 1230px;
    margin: auto;
}

#brand {
    font-weight: bold;
    font-size: 18px;
    display: flex;
    align-items: center;
    margin-left: -26.5%;
}

#brand a {
    color: #09c372;
}

.nav-list {
    list-style: none;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.nav-list a {
    color: white;
}

.nav-list li {
    padding: 5px;
    margin-left: 10px;
}

.nav-list li:hover {
    transform: scale(1.1);
    transition: 0.3s;
}

/* mobile menu */
#hamburger-icon {
    margin: auto 0;
    display: none;
    cursor: pointer;
}

#hamburger-icon.open {
margin-right: -100%;
}

#hamburger-icon div {
    width: 30px;
    height: 3px;
    background-color: #ffffff;
    margin: 6px 0;
    transition: 0.4s;
}

#hamburger-icon .social-icons {
    width: 100%;
    background: transparent;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
}

#hamburger-icon .mobile-btn {
    width: 100%;
    background: transparent;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
}

#hamburger-icon .mobile-menu .social-icons li {
    margin-bottom: 0px;
    padding: 10px;
}

.open .bar1 {
    -webkit-transform: rotate(-45deg) translate(-6px, 6px);
    transform: rotate(-45deg) translate(-6px, 6px);
}

.open .bar2 {
    opacity: 0;
}

.open .bar3 {
    -webkit-transform: rotate(45deg) translate(-6px, -8px);
    transform: rotate(45deg) translate(-6px, -8px);
}

.mobile-menu {
    display: none;
    position: absolute;
    top: 100px;
    left: 0;
    height: calc(100vh - 350px);
    width: 100%;
}

.open .mobile-menu {
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: linear-gradient(
        180deg,
        #480101 92%,
        rgba(20, 20, 20, 0.39) 57.44%,
        #480101 7%
    );
    padding: 60px 30px;
    width: 100%;
    top: 4%;
    /* left: 12%; */
    margin-left: -1%;
    z-index: -1;
}

.mobile-menu li {
    margin-bottom: 20px;
}

.mobile-menu li a {
    color: #fff;
}

.hide-wide {
    display: none;
}

.show-wide {
    display: block;
}

.mint-price {
    font-size: 1.2em;
    margin-right: 31%;
}

.kevin-smith-img {
    width: 1400px;
    height: 1400px;

   margin-left: -40%;
   margin-top: -20%;
   z-index: -1;
}


@media only screen and (max-width: 992px) {
    header nav {
        display: none;
    }

    #hamburger-icon {
        display: block;
        margin-right: 9%;
    }

    #header-background {
        /* position: relative; */
        /* margin-right: 100%; */
/* max-width: 100%; */
        /* background-repeat: no-repeat; */
        /* background-size: cover; */
        /* position: absolute; */
        left: 70%;
        /* z-index: 10; */
        top: -0.5%;
    }

    #brand {
        margin-left: -300%;
        z-index: -1;
    }

    .hero-background {
        padding-top: 10%;
    }

    .hero-text-img {
        margin-left: -2%;
    }    

    .mint-price {
        font-size: 1.2em;
        margin-right: 24%;
    }

    .kevin-smith-img-wrapper {
        width: 1400px;
        height: 1400px;
        margin-left: -40%;
        margin-top: -20%;
    }

    .kevin-smith-img {
        width: 1400px;
        height: 1400px;
    
       margin-left: -40%;
       margin-top: -20%;
       z-index: -1;
    }

    #services-background {
        margin-top: -60%;
    }
    #team-footer-background {
        margin-bottom: -10%;
    }

    .passphrase-box {
        margin-left: -22%;
        margin-top: -2%;
        margin-bottom: 8%;
    }
    .passphrase-lock{
        width: 35px !important;
        height: 50px;
        margin-left: 7%;
        margin-top: 12%;
    }

    /* .mobile-menu {
		width: 100%;
		margin-left: -50%;
	} */
}

/* @media only screen and (max-width: 729px) {

} */


.collection-hero-background {
    background: linear-gradient(
            180deg,
            #011b48 0%,
            rgba(20, 20, 20, 0.7) 48.44%,
            #011b48 100%
        ),
        url(./bgtree.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0;
}

.collection-item-background {
    background: linear-gradient(
        180deg,
        #370707 -700%,
        rgba(20, 20, 20, 0) 48.44%,
        #2f0303 400%
    ), url(./bgsword.jpeg);
/* width: 100%; */
    /* background: linear-gradient(
            180deg,
            #350b0b 0%,
            rgba(20, 20, 20, 0.7) 48.44%,
            #2f0303 100%
        ),
        url(./bgsword.jpeg); */
    /* background: linear-gradient(180deg, #DE0000 0%, #4E0000 100%); */

    background-repeat: no-repeat;
    background-size: cover;
    /* width: 1519px; */
    width: 100%;

    /* background-position: 0; */
    /* background-position:center; */
    padding-top: 4%;
    /* max-width: 80%; */
 /* max-height: 100%; */
 /* object-fit: fill */
/* 
    background: linear-gradient(
        180deg,
        #000000 0%,
        rgb(0, 0, 0) 48.44%,
        #000000 100%
    ); */

/* padding: 60px 0px; */
}

.rarity-rank-button {
    line-height: 17px;
    font-size: 16px;
    padding: 13px 45px;
    background-image: linear-gradient(180deg, #508af9 0%, #4f4bcf 100%);
    border-color: rgba(255, 255, 255, 0.8);
    border-width: 0px;
    border-radius: 6px;
    color: #fff;
    letter-spacing: 1px;
}

/*
body {
	font-size: 17px;
	font-weight: 400;
	line-height: 28.9px;
}*/
